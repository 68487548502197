import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import BusinessIntelligenceIconImage from "images/businessIntelligence.png";
import SecureIconImage from "images/secure.png";
import DataScienceIconImage from "images/dataScience2.png";
import ArtificialIntelligenceIconImage from "images/artificialIntelligience.png";
import ScrumMasterIconImage from "images/scrumMaster.png";
import SoftwareDevelopmentIconImage from "images/softwareDevelopment.png";


const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;
const Para = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-3  font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "We offer a diverse range of IT and project management services including Business Intelligence, Data Science, Artificial Intelligence, Software Development, and Scrum Master. Our services involve gathering and analyzing data, developing intelligent systems, building software solutions, and providing expert guidance in Agile methodologies. With a customer-centric approach and experienced professionals, we strive to deliver high-quality services that drive business success." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: SecureIconImage,
      title: "Secure",
      description: "We take a security first approach with our services and products."
    },
    { imageSrc: BusinessIntelligenceIconImage, title: "Business Intelligence",  description: "Transforming data into insights through customized solutions for informed decision-making and a competitive advantage." },
    { imageSrc: DataScienceIconImage, title: "Data Science", description: "Extracting valuable knowledge from complex datasets using statistical analysis and machine learning for optimized operations and growth." },
    //{ imageSrc: ArtificialIntelligenceIconImage, title: "Artificial Intelligence",  description: "Developing intelligent systems that automate tasks, improve efficiency, and drive innovation in various domains." },
    { imageSrc: ScrumMasterIconImage, title: "Scrum Master",  description: "Guiding Agile implementation, coaching teams, and optimizing project delivery for adaptability, transparency, and continuous improvement." },
    { imageSrc: SoftwareDevelopmentIconImage, title: "Software Development" , description: "Building scalable and robust software solutions tailored to meet unique client requirements for a seamless user experience."},
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading id ="services">{heading}</Heading>
        <VerticalSpacer />
        <Para>{description} </Para>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "test amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
