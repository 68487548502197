import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import EmailIllustrationSrc2 from "images/contactUs1.png";

import { useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const initialState = {
  name: '',
  email: '',
  subject: '',
  message: ''
}

//  function Contact (props) {
//   const [{ name, email, subject, message }, setState] = useState(initialState)
//   const handleChange = (e) => {
//     const { name, value } = e.target
//     setState((prevState) => ({ ...prevState, [name]: value }))
//   }
//   const clearState = () => setState({ ...initialState })

//   const handleSubmit = (e) => {
//     e.preventDefault()
//     emailjs
//       .sendForm(
//         'service_f2jegib', 'template_otr3d7q', e.target, 'akWLUSKXaGAiV71uW'
//       )
//       .then(
//         (result) => {
//           clearState()
//           e.target.reset()
//           alert("Your message was sent")
//         },
//         (error) => {
//           console.log(error.text)
//         }
//       )
//   }
// }

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "Send us a message or give us a call at 613-909-6598. Our team will get back to you as soon as possible. Our office hours are Monday - Friday 9 am to 5 pm.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [{ name, email, subject, message }, setState] = useState(initialState)
  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    if(name&&email&&message){
      emailjs
      .sendForm(
        'service_f2jegib', 'template_qz3f6zf', e.target, 'akWLUSKXaGAiV71uW'
      )
      .then(
        (result) => {
          clearState()
          e.target.reset()
          Swal.fire({
            text: "Your message was sent",
            icon: "success",
            confirmButtonText: "Okay"
          })
        },
        (error) => {
          console.log(error.text)
        }
      )
    }
    else {
      Swal.fire({
       
        text: "Please enter email address, subject and a message.",
        icon: "warning",
        confirmButtonText: "Okay"
      })
    }

  }


  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc2} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading id="contactus">{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod} onSubmit={handleSubmit}>
              <Input type="email" id="email" name="email" placeholder="Your Email Address"  onChange={handleChange} />
              <Input type="text" id="name" name="name" placeholder="Full Name"   onChange={handleChange} />
              <Input type="text" id="subject" name="subject" placeholder="Subject"   onChange={handleChange} />
              <Textarea id="message" name ="message" placeholder="Your Message Here"   onChange={handleChange} />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
